var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"min-width":"250","max-width":"250","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer","display":"flex","align-items":"center"}},'div',attrs,false),on),[_c('img',{style:({
            width: _vm.size + 'px',
            maxWidth: _vm.size + 'px',
            height: (_vm.size * 213) / 320 + 'px',
            maxHeight: (_vm.size * 213) / 320 + 'px',
          }),attrs:{"src":'https://flagcdn.com/w320/' +
            (_vm.settings.country ? _vm.settings.country.toLowerCase() : 'us') +
            '.png',"height":"213","width":"320"}}),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.showDropdown ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)]}}]),model:{value:(_vm.showDropdown),callback:function ($$v) {_vm.showDropdown=$$v},expression:"showDropdown"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Search","hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('div',{staticStyle:{"max-height":"250px","overflow-y":"auto"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.filteredOptions),function(option,index){return _c('v-list-item',{key:index,staticClass:"pa-0",on:{"click":function($event){return _vm.selectOption(option)}}},[_c('v-list-item-avatar',{attrs:{"height":"25","width":"25"}},[_c('img',{attrs:{"src":option.flags.png,"alt":option.flags.alt}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(option.name.common)+" ("+_vm._s(option.cca2)+")")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(option.region))])],1)],1)}),(_vm.filteredOptions.length === 0)?_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('no_options_found')))])],1):_vm._e()],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }