var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('v-tabs',{class:!_vm.dialog ? 'card' : '',staticStyle:{"margin-top":"0px","position":"sticky","top":"0px","z-index":"6"},attrs:{"fixed-tabs":_vm.isMobile,"center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-movie-open")]),_vm._v(_vm._s(_vm.$t('filters')))],1),_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-television")]),_vm._v(_vm._s(_vm.$t('platforms')))],1),_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(_vm._s(_vm.$t('account')))],1),_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cog")]),_vm._v(_vm._s(_vm.$t('settings')))],1),_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-download")]),_vm._v(_vm._s(_vm.$t('install')))],1),(false)?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cog")]),_vm._v(_vm._s(_vm.$t('settings_old')))],1):_vm._e()],1),_c('div',{class:!_vm.dialog ? 'card' : '',style:({
        maxHeight: _vm.isMobile ? '70vh' : '450px',
        minHeight: _vm.isMobile ? '20vh' : '250px',
        overflowY: 'auto',
        padding: '20px',
        marginTop: '15px',
      }),attrs:{"id":"tabsElementSettings"}},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('Tags2')],1),_c('v-tab-item',[_c('providers-select')],1),_c('v-tab-item',[(_vm.loggedIn)?_c('div',[_c('UserPage')],1):_c('div',[_c('Login')],1)]),_c('v-tab-item',[_c('div',[_c('OtherSettings')],1)]),_c('v-tab-item',[_c('check-extension')],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }