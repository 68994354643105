<template>
  <div class="subpage">
    <section id="main" class="wrapper" style="max-width: 700px; margin: auto">
      <div class="inner">
        <h1>{{ $t('ohana_tv_news') }}</h1>
        <p>{{ $t('stay_tuned_will_soon') }}</p>
        <p>
          {{ $t('stay_tuned_will_soon') }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
/*

let x = require('articles/about.md')
console.log('XXXXX', x)
*/
/*
files.keys().forEach((key) => {
  var x = fs.readFileSync(key, 'utf8')
  console.log('xxx0', x)
})
*/

export default {
  data() {
    return {
      key: 'value',
    }
  },
}
</script>

<style lang="scss" scoped></style>
