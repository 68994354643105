const mutations = {
  SET_SETTINGS: 'SET_SETTINGS',
  SET_HAS_APP: 'SET_HAS_APP',
  SET_EXTENSION_VERSION: 'SET_EXTENSION_VERSION',

  IS_GOOGLE_CHROME: 'IS_GOOGLE_CHROME',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_SCREEN_WIDTH: 'SET_SCREEN_WIDTH',
}

export { mutations }
